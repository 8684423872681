@mixin input() {
  @include bodyTypography(body1);
  height: $form-field-height;
  border: $form-field-border;
  border-radius: $form-field-radius;
  background-color: $color-white;

  &:focus {
    outline: unset;
    border: $form-field-border-focus;
  }

  &::placeholder {
    color: $color-gray-200;
  }

  &[aria-invalid='true'] {
    background-color: $color-error-light;
    border: $form-field-border-error;
    color: $color-error-main;
  }
}
