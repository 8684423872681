@import "src/styles";
*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  color: $color-black;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  height: 100%;
  > div#__next {
    min-height: 100vh;
  }
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0.01em 0 0 0;
}

[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  outline: none;
}

.reset-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

.typography {
  h1 {
    margin-bottom: $spacing-24;
  }
  h2 {
    margin-bottom: $spacing-16;
  }
  h3,
  h4 {
    margin-bottom: $spacing-8;
  }

  p {
    margin-bottom: $spacing-24;
  }
}

.blueScrollBar-x {
  &::-webkit-scrollbar-thumb {
    background-color: $color-primary-main;
    border: 2px solid $color-gray-100;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-gray-100;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    height: 10px;
  }
}

.blueScrollBar-y {
  &::-webkit-scrollbar-thumb {
    background-color: $color-primary-main;
    border: 2px solid $color-gray-100;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-gray-100;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }
}

// Hide cookiebot logo on cookieobt banner
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

/* Branding on the widget */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none;
}

.modalContent {
  overflow-y: auto;

  // hide scroll on mobile devices
  transform: translate3d(0, 0, 0);
  perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000;

  &::after {
    content: '';
    display: block;
    height: 0px;
    width: 100%;
    padding-bottom: $spacing-24;
  }
  &::-webkit-scrollbar {
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.25);
    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  &::-webkit-scrollbar-track {
    margin-bottom: $spacing-24;
  }
}

.modalContentGradient {
  display: block;
  width: 100%;
  height: 2rem;
  left: 0px;
  bottom: 0px;
  position: absolute;
  pointer-events: none;
  background-image: linear-gradient(
    to top,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

// Here we overide (react-phone-number-input)'s country select to match our custom styles
.PhoneInputCountry {
  width: 20%;
  justify-content: center;
  @include input();
}

.PhoneInputCountrySelectArrow {
  display: none !important;
}

.PhoneInputCountryIcon {
  width: fit-content !important;
  height: map-get($font-lines-body, 'body1') !important;
}

.PhoneInputCountry {
  flex: 0.6;
  @include breakpoint-md {
    flex: 0.5;
  }
}

:root {
  --PhoneInputCountryFlag-backgroundColor--loading: none !important;
  --PhoneInputCountryFlag-borderWidth: 0 !important;
}

// overrides for the HubSpot form within the `FooterSubscribe` component
#hbsptForm {
  & .hs-form-private {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;

    // hide the summary of all the form errors
    & .hs_error_rollup {
      display: none;
    }

    & .hs-form-field {
      width: 100%;

      & .hs-input {
        @include input();
        font-weight: $font-weight-bold;

        &::placeholder {
          font-weight: $font-weight-normal;
        }

        width: 100%;
        padding: {
          right: $spacing-16;
          left: $spacing-16;
        }

        &.error {
          border-color: $color-warning-main;
          color: $color-warning-main;
        }
        &.invalid {
          background-color: $color-input-error-bg;
          &::-webkit-input-placeholder {
            color: $color-warning-main;
          }
          &::-moz-placeholder {
            color: $color-warning-main;
          }
          &::-ms-input-placeholder {
            color: $color-warning-main;
          }
          &:-ms-input-placeholder {
            color: $color-warning-main;
          }
        }
      }

      & .hs-error-msgs {
        margin: unset;
        padding: unset;
        list-style: none;

        & > li {
          @include bodyTypography(body2);
          font-weight: $font-weight-bold;
          padding: {
            left: $spacing-16;
            right: $spacing-16;
          }
          margin-top: $spacing-4;
          text-align: left;

          & > label {
            // desaturated #color-warning-main
            color: #ffe0b0;
          }
        }
      }
    }

    & .hs-button {
      @include bodyTypography(body1, $color-black, $font-weight-bold);
      position: relative;
      border-width: 1px;
      border-radius: 2px;
      border-style: solid;
      border-color: transparent;
      text-decoration: none;
      text-align: center;
      min-height: 50px;
      width: 100%;

      &.primary {
        background-color: $color-secondary-main;
        border-color: $color-secondary-main;
        color: $color-black;
        padding: $spacing-12 $spacing-24;

        &:hover,
        &:active {
          &:not([disabled]) {
            background-color: $color-secondary-dark;
            border-color: $color-secondary-dark;
            cursor: pointer;
          }
        }
      }
    }
  }

  & .submitted-message {
    color: $color-white;
    font-weight: $font-weight-bold;
  }
}
