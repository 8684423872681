@import "src/styles";
#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: $color-secondary-main;
  position: fixed;
  z-index: $z-index-progress-bar;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.3px;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
